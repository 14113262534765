<script setup lang="ts">
const { breadcrumbs } = useBreadcrumbs();
</script>

<template>
  <LayoutWrapperDiv :class="$style.breadcrumbs">
    <ol :class="$style.list">
      <BreadcrumbsItem
        v-for="(item, i) in breadcrumbs[0]"
        :key="item.name"
        :index="i"
        :label="item.name"
        :path="item.path || ''"
      />
    </ol>
  </LayoutWrapperDiv>
</template>

<style scoped module>
.list {
  @apply flex items-center overflow-x-scroll h-full;

  /* Firefox 対応 */
  scrollbar-width: none;
  /** Chrome・Safari・Microsoft Edge 以前 */
  -ms-overflow-style: none;
  /** Chrome・Safari・Microsoft Edge 上が効かない最近のバージョン */
  &::-webkit-scrollbar {
    display: none;
  }
}
.breadcrumbs {
  height: 36px;
}
</style>
